import { useEffect, useState } from "react";
import qs from "query-string";
import { useLocation } from "react-router-dom";

const TwitterCallback = () => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const query = qs.parse(location.search, { ignoreQueryPrefix:true});
    if (query.oauth_token === localStorage.getItem("oauthTwitterToken")) {
      fetch(`${process.env.REACT_APP_API_URL}/callback/${localStorage.getItem(
        "oauthTwitterToken"
      )}/${localStorage.getItem("oauthTwitterTokenSecret")}/${
        query.oauth_verifier
      }`).then(res => res.json())
      .then((res) => {
        if (res.oauthAccessToken && res.oauthAccessTokenSecret) {
          localStorage.setItem('twitterAccessToken', res.oauthAccessToken)
          localStorage.setItem('twitterAccessTokenSecret', res.oauthAccessTokenSecret)
        }
        setIsLoading(false);
      })
    }
  },[])

  return (
    <div style={{
      background: "#282c34",
      position: "fixed",
      top:0,
      left:0,
      right:0,
      bottom:0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "white",
      fontSize: "24px"
    }}>{isLoading ? <>Loading...</> : <>Thank you. You can know close the tab and go back to Regular Meme generator.</>}</div>
  )
}

export default TwitterCallback;
