const SizePicker = ({size, setSize}) => {
    return (
        <div style={{marginBottom: "10px",display: 'flex', flexWrap: "wrap", gap: "8px", alignItems: "center", fontSize: "18px", lineHeight: "18px"}}>
            <div>Size:</div>
            <button onClick={() => setSize(20)} style={{fontWeight: size===20 ? 700 : 400, fontSize: "16px"}}>
                small
            </button>
            <button onClick={() => setSize(24)} style={{fontWeight: size===24 ? 700 : 400, fontSize: "18px"}}>
                regular
            </button>
            <button onClick={() => setSize(30)} style={{fontWeight: size===30 ? 700 : 400, fontSize: "20px"}}>
                large
            </button>
            {/* <button onClick={() => setSize(40)} style={{fontWeight: size===40 ? 700 : 400, fontSize: "24px"}}>
                xlarge
            </button> */}
        </div>
    )
}

export default SizePicker;
