import { collection, doc, getFirestore, serverTimestamp, setDoc } from "@firebase/firestore";
import { getStorage, ref, uploadBytes } from "@firebase/storage";
import { initializeApp } from "@firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDqP2ggck0Vd6nZbYdYXvO3QF0wkygLTkI",
  authDomain: "regularswap.firebaseapp.com",
  projectId: "regularswap",
  storageBucket: "regularswap.appspot.com",
  messagingSenderId: "724105269705",
  appId: "1:724105269705:web:3769d3ccb573b4fefbd818",
  measurementId: "G-6W9PNQHW86"
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);

export const addMeme = async (meme) => {
  const { tokenId, topLine, topLine2, bottomLine, bottomLine2, collectionName, tokenAddress, blob } = meme;
  const url = 'https://firebasestorage.googleapis.com/v0/b/regularswap.appspot.com/o/';
  const newMemeRef = doc(collection(db, "memes"));
  const uploaded = await uploadMeme(blob, newMemeRef.id);

  if (!uploaded) return null;

  try {
    await setDoc(newMemeRef, {
      id: newMemeRef.id,
      tokenId,
      topLine,
      bottomLine,
      collectionName,
      tokenAddress,
      bottomLine2: bottomLine2 || null,
      topLine2: topLine2 || null,
      image: `${url}memes%2F${newMemeRef.id}.png?alt=media`,
      createdAt: serverTimestamp(),
    })
    return `${url}memes%2F${newMemeRef.id}.png?alt=media`;
  } catch (e) {
    console.log(e)
  }
  return null;
}

const uploadMeme = async (file, filename) => {
  const storage = getStorage(firebaseApp);
  const metadata = {
    contentType: `image/png`,
  };
  const storageRef = ref(storage, `memes/${filename}.png`);
  try {
    await uploadBytes(storageRef, file, metadata);
    return true;
  } catch (e) {
    console.log(e);
    return null;
  }
}