
const ColorPicker = ({color, setColor}) => {

    return (
        <>
        <div style={{display: 'flex', flexWrap: "wrap", gap: "8px", alignItems: "center", fontSize: "18px", lineHeight: "18px"}}>
        Color:
        <button onClick={() => setColor('white')} style={{border: color==='white' ? 'solid 2px black': 'none',background: 'white', borderRadius: "5px", width: "30px", height: "30px", cursor:"pointer" }}></button>
        <button onClick={() => setColor('black')} style={{border: color==='black' ? 'solid 2px white': 'none',background: 'black', borderRadius: "5px", width: "30px", height: "30px", cursor:"pointer" }}></button>
        <button onClick={() => setColor('red')} style={{border: color==='red' ? 'solid 2px black': 'none',background: 'red', borderRadius: "5px", width: "30px", height: "30px", cursor:"pointer" }}></button>
        <button onClick={() => setColor('blue')} style={{border: color==='blue' ? 'solid 2px black': 'none',background: 'blue', borderRadius: "5px", width: "30px", height: "30px", cursor:"pointer" }}></button>
        </div>
        </>
    )
}

export default ColorPicker