import '../App.css';
import Swal from 'sweetalert2';
import { useCallback, useEffect, useRef, useState } from 'react';
import ColorPicker from '../ColorPicker';
import SizePicker from '../SizePicker';
import { addMeme } from '../firebase';

const nftUrl = (tokenId) => {
  return `https://firebasestorage.googleapis.com/v0/b/nft-collections-2dc2c.appspot.com/o/regulars%2F${tokenId}?alt=media`
}

const X_TOP_COORDINATE = 40;

const splitLinesAt = {
  20: 34,
  24: 28,
  30: 22,
}

const getYCoordinatesBasedOnLines = (line1, line2, size, isTop) => {
  const aDoubleLine = X_TOP_COORDINATE+(size-20)/2*(isTop ? 1 : -1) -size/4*(isTop ? 1 : -1);
  const bDoubleLine =X_TOP_COORDINATE+(size-20)/2*(isTop ? 1 : -1) -size/4*(isTop ? 1 : -1) +size*(isTop ? 1 : -1);
  const aSigleLine = X_TOP_COORDINATE+(size-20)/2*(isTop ? 1 : -1);
  const bSingleLine = X_TOP_COORDINATE+(size-20)/2*(isTop ? 1 : -1) +size*(isTop ? 1 : -1);
  if (line1.length > splitLinesAt[size] || line2) {

    return [isTop ? aDoubleLine : bDoubleLine, isTop ? bDoubleLine : aDoubleLine]
  }
  return [isTop ? aSigleLine : bSingleLine, isTop ? bSingleLine : aSigleLine]
}

function Home() {
  const [topLine, setTopLine] = useState('');
  const [topLine2, setTopLine2] = useState('');
  const [showSecondTopLine, setShowSecondTopLine] = useState(false);
  const [topColor, setTopColor] = useState('white');
  const [topSize, setTopSize] = useState(24);
  const [bottomLine, setBottomLine] = useState('');
  const [bottomLine2, setBottomLine2] = useState('');
  const [showBottomLine2, setShowBottomLine2] = useState('');
  const [bottomColor, setBottomColor] = useState('white');
  const [bottomSize, setBottomSize] = useState(24);
  const [regularId, setRegularId] = useState(Math.floor(Math.random() * 10000));
  const [imgUrl, setImgUrl] = useState(nftUrl(regularId));
  const [topBuffer, setTopBuffer] = useState(0);
  const [bottomBuffer, setBottomBuffer] = useState(0);
  const [tweetText, setTweetText] = useState("Created with https://meme.regular.world #Regulars_NFT #Regulars #KeepItRegular");
  const [generatedUrl, setGeneratedUrl] = useState(null);


  const imgRef = useRef();
  const canvasRef = useRef();

  const createMeme = useCallback(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(imgRef.current, 0, 0, 400, 400);
    ctx.font=`${topSize}px Roboto`;
    ctx.fillStyle=topColor;
    ctx.textAlign = 'center';
    ctx.fillText(topLine ? topLine.slice(0,splitLinesAt[topSize]):'Top line text', 200 ,
    topBuffer + getYCoordinatesBasedOnLines(topLine,topLine2, topSize, true)[0]);
    if (topLine2) {
      ctx.fillText(topLine2, 200, topBuffer + getYCoordinatesBasedOnLines(topLine,topLine2, topSize, true)[1])
    } else if (topLine.length > splitLinesAt[topSize]) {
      ctx.fillText(topLine.slice(splitLinesAt[topSize],topLine.length), 200,
      topBuffer + getYCoordinatesBasedOnLines(topLine,topLine2, topSize, true)[1])
    }
    ctx.font=`${bottomSize}px Roboto`;
    ctx.fillStyle=bottomColor;
    ctx.fillText(bottomLine ? bottomLine.slice(0,splitLinesAt[bottomSize]):'Bottom line text', 200,
    bottomBuffer+ 340+getYCoordinatesBasedOnLines(bottomLine, bottomLine2, bottomSize)[0]);
    if (bottomLine2) {
      ctx.fillText(bottomLine2 ? bottomLine2:'Bottom line2 text', 200,
      bottomBuffer+340+getYCoordinatesBasedOnLines(bottomLine, bottomLine2, bottomSize)[1]);
    } else if (bottomLine.length > splitLinesAt[bottomSize]){
      ctx.fillText(bottomLine.slice(splitLinesAt[bottomSize],bottomLine.length), 200,
      bottomBuffer+340+getYCoordinatesBasedOnLines(bottomLine, bottomLine2, bottomSize)[1]);
    }
    setGeneratedUrl(null);
  }, [topColor,bottomLine2, topLine,topLine2, topSize,regularId, bottomSize, bottomColor, bottomLine, topBuffer, bottomBuffer])

  useEffect(() => {
    createMeme();
  }, [regularId, topColor, topSize, topLine, topLine2,bottomColor, bottomSize, bottomLine,bottomLine2,topBuffer, bottomBuffer]);

  useEffect(() => {
    setImgUrl(nftUrl(regularId))
  }, [regularId])
  /* eslint-disable */
  const copyClipboard = () => {
    const canvas = canvasRef.current;
    canvas.toBlob(function(blob) { 
      navigator.clipboard.write([
        new ClipboardItem({ "image/png": blob }),
      ]);
  });
  }
  /* eslint-disable */

  const handleSave = () => {
    const canvas = canvasRef.current;
    canvas.toBlob((blob) => {
      save(blob, 'regular-meme.png');
    })

  }

  function save(blob, name) {
    name = name || 'download';
  
    // Use native saveAs in IE10+
    if (typeof navigator !== "undefined") {
      if (/MSIE [1-9]\./.test(navigator.userAgent)) {
        alert('IE is unsupported before IE10');
        return;
      }
      if (navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(blob, name);
        return;
      }
    }
  
    // Construct URL object from blob
    var win_url = window.URL || window.webkitURL || window;
    var url = win_url.createObjectURL(blob);
  
    // Use a.download in HTML5
    var a = document.createElementNS('http://www.w3.org/1999/xhtml', 'a');
    if ('download' in a) {
      a.href = url;
      a.download = name;
      a.dispatchEvent(new MouseEvent('click'));
      // Don't revoke immediately, as it may prevent DL in some browsers
      setTimeout(function() { win_url.revokeObjectURL(url); }, 500);
      return;
    }
  
    // Use object URL directly
    window.location.href = url;
    // Don't revoke immediately, as it may prevent DL in some browsers
    setTimeout(function() { win_url.revokeObjectURL(url); }, 500);
  }

  const handleMovingTop = (e) => {
    if (e.clientY) {
      setTopBuffer(e.clientY-108)
    }

  }

  const handleMovingBottom = (e) => {
    if (e.clientY) {
      setBottomBuffer(e.clientY-435)
    }
  }
  const buttonRef= useRef();
  const tweetRef = useRef();
  const publishMeme = () => {

    if (!topLine || !bottomLine) {
      Swal.fire({
        title:"Error",
        text:"You have to write top and bottom line.",
        icon: "error",
        confirmButtonText: "Okay"
      })
      return
    };
    buttonRef.current.disabled = true;
    const collectionName = "Regulars";
    const tokenAddress = "0x6d0de90cdc47047982238fcf69944555d27ecb25";
    const canvas = canvasRef.current;
    canvas.toBlob(async (blob) => {
      const memeUrl = await addMeme({topLine, topLine2, tokenId: regularId, bottomLine, bottomLine2, collectionName, tokenAddress, blob});
      setGeneratedUrl(memeUrl);
      if (memeUrl) {
        Swal.fire({
          title:"Published to Discord",
          text:"You have successfully published your meme to Regular Discord",
          icon: "success",
          confirmButtonText: "Cool"
        })

      }
      if (!memeUrl) {
        Swal.fire({
          title:"Error",
          text:"There have been an error. Please try again later or contact us at Discord",
          icon: "error",
          confirmButtonText: "Balls"
        })
      }
    })
   setTimeout(() => {buttonRef.current.disabled=false}, 5000)
  }

  const startTwitterOauth = () => {
    Swal.fire({
      title:"Twitter sign-in",
      text:"You will need to provide permission to tweet the meme",
      icon: "info",
      confirmButtonText: "Ok"
    }).then(() => {
      fetch(`${process.env.REACT_APP_API_URL}/start-auth`)
      .then(res=> res.json())
      .then(res => {
        console.log(res)
        window.open(res.redirectUrl ,'mywin','width=500,height=500')
        localStorage.setItem('oauthTwitterToken', res.oauthRequestToken);
        localStorage.setItem('oauthTwitterTokenSecret', res.oauthRequestTokenSecret);
      })
    })

  }

  const composingTweet = () => {

    if (!topLine || !bottomLine) {
      Swal.fire({
        title:"Error",
        text:"You have to write top and bottom line.",
        icon: "error",
        confirmButtonText: "Okay"
      })
      return
    }
    if (!localStorage.getItem('twitterAccessToken') || !localStorage.getItem('twitterAccessTokenSecret') ||
    localStorage.getItem('twitterAccessToken') === 'undefined' || localStorage.getItem('twitterAccessTokenSecret') === 'undefined') {
      startTwitterOauth();
      return;
    }
    tweetRef.current.disabled = true;
    const collectionName = "Regulars";
    const tokenAddress = "0x6d0de90cdc47047982238fcf69944555d27ecb25";
    const canvas = canvasRef.current;
    canvas.toBlob(async (blob) => {
      const memeUrl = generatedUrl || await addMeme({topLine, topLine2, tokenId: regularId, bottomLine, bottomLine2, collectionName, tokenAddress, blob});
      setGeneratedUrl(memeUrl);
      if (memeUrl) {
        console.log(process.env.REACT_APP_API_URL)
       await fetch(`${process.env.REACT_APP_API_URL}/permission-tweet/${localStorage.getItem('twitterAccessToken')}/${localStorage.getItem('twitterAccessTokenSecret')}?tweet=${encodeURIComponent(tweetText)}&memeUrl=${memeUrl}`)
       .then((res) => {
         if (!res.ok){
           if (res.status === 401) {
            Swal.fire({
              title:"Twitter session expired.",
              text:"Your session with twitter has expired. We need to create a new one",
              icon: "error",
              denyButtonText:"Cancel",
              confirmButtonText: "Ok"
            }).then((result) => {
              if (!result.isConfirmed) return;
              fetch(`${process.env.REACT_APP_API_URL}/start-auth`)
              .then(res=> res.json())
              .then(res => {
                console.log(res)
                window.open(res.redirectUrl ,'mywin','width=500,height=500')
                localStorage.setItem('oauthTwitterToken', res.oauthRequestToken);
                localStorage.setItem('oauthTwitterTokenSecret', res.oauthRequestTokenSecret);
              })
            })
           } else {
            Swal.fire({
              title:"Error",
              text:"There was an error please try again later.",
              icon: "error",
              confirmButtonText: "Ok"
            })
           }

         throw Error({status: res.status, text: res.statusText})
         }
        Swal.fire({
          title:"Tweeted",
          text:"You have successfully tweeted your meme to Twitter",
          icon: "success",
          confirmButtonText: "Cool"
        })
       })
       .catch((e) => {
         console.log(e.status);

       })

      }
      if (!memeUrl) {
        Swal.fire({
          title:"Error",
          text:"There have been an error. Please try again later or contact us at Discord",
          icon: "error",
          confirmButtonText: "Balls"
        })
      }
    })
   setTimeout(() => {tweetRef.current.disabled=false}, 5000)
  }
  
  return (
    <div className="App">
      <header className="App-header">
      <p style={{marginTop: "10px"}}>
          
          Create your Regular Meme
        </p>
        {!isNaN(regularId) && (
          <div style={{position: "relative"}}>
          <img crossOrigin="anonymous" ref={imgRef} src={imgUrl} alt="nft" style={{width: '250px', display:'none'}} onLoad={() => createMeme()}/>
          <canvas ref={canvasRef} width="400px" height="400px" style={{width:"100%", maxWidth: "400px"}}/>
          <button draggable onDrag={handleMovingTop}
          style={{ opacity:0, position: 'absolute',left: '125px', width: '150px', height: `50px`, top: `${10+topBuffer}px`}}>
          </button>
          <button draggable onDrag={handleMovingBottom}
          style={{ opacity:0, position: 'absolute',left: '125px', width: '150px', height: `50px`, top: `${300+bottomBuffer}px`}}>
          </button>
          </div>
        )}
        <label htmlFor='regularId' style={{display:'flex', flexWrap:"wrap", gap:"10px", paddingBlock:"10px"}}>
          Regular:
          <input id="regularId" style={{width:"70px",fontSize: "20px", textAlign: "center"}} value={regularId} onChange={(e) => { if (Number(e.target.value) >-1 && Number(e.target.value) <10000)setRegularId(Number(e.target.value))}} placeholder="Regular NFT Id" />
          <button onClick={() => setRegularId(Math.floor(Math.random() * 10000))}>I feel regular</button>
        </label>
        
        <div style={{position: "relative",padding: "10px",marginBottom: "10px", border: "1px solid white", borderRadius: "10px", width:'100%', maxWidth: "400px", alignItems:'center'}}>
        <div style={{ lineHeight: "10px", fontSize: "14px", position: "absolute", top: "-8px", left: "20px", background:"#282c34", paddingInline: "5px"}}>Top line</div>
        <label htmlFor='topline' style={{marginBottom: "10px",display: "flex", gap:"10px", flexWrap:"wrap"}}>
          <div style={{fontSize:"18px"}}>Text:</div><input id="topline" value={topLine} onChange={(e) => setTopLine(e.target.value)} placeholder="Top line" />
          {!showSecondTopLine && <div style={{display:'inline-flex'}} ><button style={{ padding: "2px 7px",fontSize: "20px", height:'30px', width:"30px"}} onClick={() => setShowSecondTopLine(true)}>+</button></div>}
          {showSecondTopLine && (
            <div style={{ display:"inline-flex", gap:"10px"}}>
            <div style={{fontSize:"18px", visibility: 'hidden', display: "inline-flex"}}>Text:</div>
             <input id="topline" value={topLine2} onChange={(e) => setTopLine2(e.target.value)} placeholder="Top line2" />
             <div style={{display:'inline-flex'}} ><button style={{fontSize:"20px", lineHeight: "14px", height:"30px", width:"30px",padding: "2px 7px", paddingBottom: '5px'}} onClick={() => {setShowSecondTopLine(false);setTopLine2('')}}>-</button></div>
            </div>
          )}
        </label>
        <SizePicker size={topSize} setSize={setTopSize} />
        <ColorPicker color={topColor} setColor={setTopColor} />
        </div>
        
        <div style={{position: "relative", padding: "10px",marginBottom: "10px", border: "1px solid white", borderRadius: "10px", width: '100%',maxWidth: "400px", alignItems:'center'}}>
        <div style={{ lineHeight: "10px",fontSize: "14px",position: "absolute", top: "-8px", left: "20px", background:"#282c34", paddingInline: "5px"}}>Bottom line</div>
        <label htmlFor='topline' style={{marginBottom: "10px",display: "flex", gap:"10px", flexWrap:"wrap"}}>
          <div style={{fontSize:"18px"}}>Text:</div><input id="topline" value={bottomLine} onChange={(e) => setBottomLine(e.target.value)} placeholder="Bottom line" />
          {!showBottomLine2 && <div style={{display:'inline-flex'}} ><button style={{ padding: "2px 7px",fontSize: "20px", height:'30px', width:"30px"}} onClick={() => setShowBottomLine2(true)}>+</button></div>}
          {showBottomLine2 && (
            <div style={{ display:"inline-flex", gap:"10px"}}>
            <div style={{fontSize:"18px", visibility: 'hidden', display: "inline-flex"}}>Text:</div>
             <input id="bottomline2" value={bottomLine2} onChange={(e) => setBottomLine2(e.target.value)} placeholder="Bottom line2" />
             <div style={{display:'inline-flex'}} ><button style={{fontSize:"20px", lineHeight: "14px", height:"30px", width:"30px",padding: "2px 7px", paddingBottom: '5px'}} onClick={() => {setShowBottomLine2(false);setBottomLine2('')}}>-</button></div>
            </div>
          )}
        </label>
        <SizePicker size={bottomSize} setSize={setBottomSize} />
        <ColorPicker color={bottomColor} setColor={setBottomColor} />
        </div>
        <div style={{position: "relative", padding: "10px",marginBottom: "10px", border: "1px solid white", borderRadius: "10px", width: '100%',maxWidth: "400px", alignItems:'center'}}>
        <div style={{ lineHeight: "10px",fontSize: "14px",position: "absolute", top: "-8px", left: "20px", background:"#282c34", paddingInline: "5px"}}>Twitter tweet</div>
        <label htmlFor='topline' style={{marginBottom: "10px",display: "flex", gap:"10px", flexWrap:"wrap"}}>
          <div style={{fontSize:"18px", display: "flex", width: "100%", gap: "10px"}}>Text: <textarea id="tweetText" style={{width: "100%", height: '70px'}} value={tweetText}
          onChange={(e) => {
            if (e.target.value.length < 280) {setTweetText(e.target.value)}
          }}
          placeholder="Tweet text" /></div>
        
        </label>
        </div>
        <div style={{display:"flex", gap: "20px", marginBottom: '20px'}}>
          <button onClick={publishMeme} ref={buttonRef}>
            Share to Discord
          </button>
          <button onClick={composingTweet} ref={tweetRef}>
            Tweet your meme
          </button>
        </div>
        <div style={{display:"flex", gap: "20px", marginBottom: '20px'}}>
        <button onClick={handleSave}>
        Save Meme
      </button>
      <button onClick={copyClipboard}>
        Copy Meme (Beta)
      </button>
      </div>
      </header>

    </div>
  );
}

export default Home;
